import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,
  Avatar,
  ButtonBase,
  Menu,
  MenuItem,
  ThemeProvider,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { tonsToLbs } from "@aclymatepackages/converters";
import { formatDecimal } from "@aclymatepackages/formatters";
import {
  mergeDarkTheme,
  useLayoutHelpers,
  isColorDarkTheme,
  mergeMainTheme,
} from "@aclymatepackages/themes";

const GraphBar = ({
  color,
  categoryMaxTons,
  dataCategoryTons,
  label,
  outlined,
}) => {
  const { isMobile } = useLayoutHelpers();

  const maxPercentage = dataCategoryTons / categoryMaxTons;

  const barPercentage = maxPercentage * 100;

  const barSize = isMobile ? 40 : 50;

  const dataCategoryDisplayValue = formatDecimal(
    Math.round(tonsToLbs(dataCategoryTons))
  );

  const SmallBarAnnotation = () => (
    <Box
      style={{
        height: barSize - 10,
        width: barSize - 10,
        backgroundColor: "white",
        borderRadius: "50%",
        border: `${color} solid thin`,
        color,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant={isMobile ? "caption" : "subtitle1"}
        align="center"
        color="textPrimary"
      >
        {dataCategoryDisplayValue}
      </Typography>
    </Box>
  );

  const barPercentageMobileBreakPoint = isMobile ? 25 : 15;
  const borderStyle = outlined ? `${color} solid thick` : "none";

  return (
    <ThemeProvider
      theme={
        isColorDarkTheme(color) && !outlined ? mergeDarkTheme : mergeMainTheme
      }
    >
      <Box
        display="flex"
        alignItems="center"
        style={{ width: "100%" }}
        columnGap={1}
      >
        <Box
          style={{
            width: `${barPercentage > 100 ? 100 : barPercentage}%`,
            height: `${barSize}px`,
            backgroundColor: outlined ? "white" : color,
            borderRadius: "0 30px 30px 0",
            borderTop: borderStyle,
            borderBottom: borderStyle,
            borderRight: borderStyle,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {barPercentage > barPercentageMobileBreakPoint && (
            <div>
              <Typography
                variant={isMobile ? "subtitle1" : "h6"}
                color="textPrimary"
                align="center"
              >
                {`${dataCategoryDisplayValue} lbs`}
              </Typography>
            </div>
          )}
        </Box>
        {barPercentage < barPercentageMobileBreakPoint && (
          <SmallBarAnnotation />
        )}
        <Typography variant="body2">{label}</Typography>
      </Box>
    </ThemeProvider>
  );
};

const CategoryDisplayRow = ({ icon, color, label, action }) => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item>
      <Avatar sx={{ bgcolor: color }}>
        <FontAwesomeIcon icon={icon} />
      </Avatar>
    </Grid>
    <Grid item>
      <Typography variant="h5">{label}</Typography>
    </Grid>
    {action && <Grid item>{action}</Grid>}
  </Grid>
);

const ComparisonChart = ({ categories }) => {
  const { theme } = useLayoutHelpers();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0);
  const {
    label: selectedLabel,
    icon: selectedIcon,
    color: selectedColor,
    tonsCo2e: selectedTonsCo2e,
    averageTonsCo2e: selectedAverageTonsCo2e,
  } = categories[selectedCategoryIdx];

  const categoryMaxTons = Math.max(selectedTonsCo2e, selectedAverageTonsCo2e);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item container justifyContent="center">
        <Grid item>
          <ButtonBase onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Box
              p={1}
              sx={{
                bgcolor: theme.palette.backgroundGray.main,
                borderRadius: theme.spacing(6),
              }}
            >
              <CategoryDisplayRow
                label={selectedLabel}
                icon={selectedIcon}
                color={selectedColor}
                action={<KeyboardArrowDownIcon sx={{ fontSize: "2.5em" }} />}
              />
            </Box>
          </ButtonBase>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            {categories.map(({ icon, color, label }, idx) => (
              <MenuItem
                key={`comparison-chart-menu-item-${idx}`}
                onClick={() => {
                  setSelectedCategoryIdx(idx);
                  return setAnchorEl(null);
                }}
              >
                <CategoryDisplayRow icon={icon} color={color} label={label} />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="stretch">
          <Box
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: theme.spacing(2),
              bgcolor: theme.palette.backgroundGray.main,
            }}
          >
            <Avatar sx={{ bgcolor: selectedColor }}>
              <FontAwesomeIcon icon={selectedIcon} />
            </Avatar>
          </Box>
          <Box py={2} flexGrow={1}>
            <Grid container spacing={4} direction="column">
              <Grid item xs={12}>
                <GraphBar
                  color={selectedColor}
                  categoryMaxTons={categoryMaxTons}
                  dataCategoryTons={selectedTonsCo2e}
                  label="You"
                />
              </Grid>
              <Grid item>
                <GraphBar
                  color={selectedColor}
                  categoryMaxTons={categoryMaxTons}
                  dataCategoryTons={selectedAverageTonsCo2e}
                  outlined
                  label="U.S Avg."
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default ComparisonChart;
